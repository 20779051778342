// *******~ Import ~******** //
// React
import React, { useState, useEffect,useContext } from "react";
import { Helmet } from "react-helmet";
// Assets
import { Container, Row, Col, Image } from "react-bootstrap";
import { Link, useNavigate, useLocation } from "react-router-dom";
import ThemeContext from "../../../../common/theme/components/contexts/themecontexts";
// CSS
// import "./css/wallet.scss";
import "./css/walletV2.scss";
// Images
// import WalletImg from "./img/savecard.png";
// import SaveCardImg from "./img/1_Saved Cards.svg";
// import CardHisImg from "./img/wallethistory.svg";
// Icons
// import { HiArrowSmRight } from "react-icons/hi";
// *******~ Import ~******** //

import { connect } from "react-redux";
import { getWalletDashboard } from "../../../../actions/myaccount/wallet/walletDashboardActions";

const MoneyPage = ({
  walletdashboard,
  error,
  userData,
  getWalletDashboard,
}) => {
  const [width, setWidth] = useState(window.innerWidth);
  const breakpoint = 767;
  const { theme } = useContext(ThemeContext);
  
  useEffect(() => {
    const handleResizeWindow = () => setWidth(window.innerWidth);
    window.addEventListener("resize", handleResizeWindow);
    return () => window.removeEventListener("resize", handleResizeWindow);
  }, []);
  
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (!location.pathname.includes("/myaccount") && width > breakpoint) {
      navigate("/myaccount/money");
    }
  }, [location, width, navigate]);

  const canonicalUrl = `${window.location.origin}${location.pathname}`;

  useEffect(() => {
    if (userData) {
      getWalletDashboard({
        customer_id: userData.customerId,
        body: "Your body",
      });
    }
  }, [userData, getWalletDashboard]);

  if (error) {
    return <div>Error: {error}</div>;
  }

  const walletdashboardamount = walletdashboard?.data;
  const Imagetopdark = 'https://fusionbucket.co.uk/My-account/arrowwhitecolor.png';
  const Imagetop = 'https://fusionbucket.co.uk/BlogImages/My-accoount-arrow.png';

  return (
    <>
      <Helmet>
        <style type="text/css">{`
          footer {
            display: none;
          }
        `}</style>
      </Helmet>
      <Helmet>
        <link rel="canonical" href={canonicalUrl} />
        <title>Fusion Kitchen | Money</title>
      </Helmet>
      {width > breakpoint ? (
         <div className='money-filefull'>
          
         <h5 className='arrow-text'>
         <img src={theme === "dark" ? Imagetopdark : Imagetop} alt="arrow-icon" className="arrow-iconwallet"  onClick={() => navigate('/myaccount')}/>
         Wallet</h5>
         {/* <div className='money-text'>
           <img
             src="https://fusionbucket.co.uk/My-account/arrowwhitecolor.png"
             alt="arrow-icon"
             className='arrow-icons'
             onClick={() => navigate('/myaccount')}
           />
           <h4 className='money-htag'>M O N E Y</h4>
         </div> */}
         <Container>
           <div className="money-page">
             <Row className='card-moneypage'>
               <Col xs={7}>
                 <p className='card-textptag'>
                   Fusion Kitchen For <br />
                   <span className='card-spantet'>Enterprises</span>
                   <br /> Simplifying Food Ordering For business
                 </p>
               </Col>
               <Col xs={5} className='wallet-image'>
                 <img
                   src="https://fusionbucket.co.uk/My-account/walletimage.png"
                   alt="wallet-image"
                 />
               </Col>
             </Row>
           
           </div>
           {/* <div className="line-container">
             <div className="line"></div>
             <div className="text">W A L L E T</div>
             <div className="line"></div>
           </div> */}
           <Row>
<Col xs={12} sm={12} md={12} lg={6} xl={6} xxl={6}>
           <div className='click-payment'>
             <Row className='click-payment1'>
               <Col xs={7}>
                 <h4 className='htagfusion-wallet'>
                   Fusion <br />
                   Wallet
                 </h4>
                 <p className='ptagfusion-wallet'>Single Click payment</p>
               </Col>
               <Col xs={5}>
                 <img
                   src="https://fusionbucket.co.uk/My-account/wallet with bank cards.png"
                   alt="wallet-with-bank"
                   className='image-col'
                 />
               </Col>
             </Row>
             <p className='create-text' onClick={() => navigate('/myaccount/savedcards')}>
               View Cards{" "}
               <img
                 src="https://fusionbucket.co.uk/My-account/Vector.png"
                 alt="vector-small"
               />
             </p>
           </div>
           </Col>
           {/* <div className="line-container">
             <div className="line"></div>
             <div className="text">H I S T O R Y  C A R D</div>
             <div className="line"></div>
           </div> */}
           <Col xs={12} sm={12} md={12} lg={6} xl={6} xxl={6}>
           <div className='click-payment'>
             <Row className='Gift-cards'>
               <Col xs={7}>
                 <h4 className='htagfusion-wallet'>History</h4>
                 <p className='ptagfusion-wallet'>Buy & Share Instantly 4 Years Validity</p>
               </Col>
               <Col xs={5}>
                 <img
                    src="https://fusionbucket.co.uk/My-account/wallet with bank cards.png"
                   alt="wallet-with-bank"
                   className='image-col'
                 />
               </Col>
             </Row>
             <p className='create-text' onClick={() => navigate('/myaccount/wallethistory')}>
               View History{" "}
               <img
                 src="https://fusionbucket.co.uk/My-account/Vector.png"
                 alt="vector-small"
               />
             </p>
           </div>
           </Col>
           </Row>
           <Row>
              <Col  xs={12} sm={12} md={12} lg={12} xl={12} xxl={12} >
             <Row className='Fusion-card'>
               <Col xs={9}>
                 <h6 className='ptag-money'>
                   <img
                     src="https://fusionbucket.co.uk/My-account/walletimage2.png"
                     alt=""
                   />{" "}
                   Fusion Kitchen Money
                 </h6>
                 <p className='ptag-verify'>View Mobile Fusion Money Balance</p>
               </Col>
               <Col className='link-text' xs={3}>
                 <h2 className="money-ponds">£ {walletdashboard?.status ? walletdashboardamount : 0}</h2>
               </Col>
             </Row>
             </Col>
             {/* <Col  xs={12} sm={12} md={12} lg={6} xl={6} xxl={6}>
             <div className="payments-full" onClick={() => navigate('/paymentsetting')}>
            <div className="text-content">
              <span className="payment-settings">Payment Settings</span>
            </div>
            <img
              src="https://fusionbucket.co.uk/BlogImages/Arrow-vector.png"
              alt=""
            />
          </div>
          </Col> */}
          </Row>
         </Container>
       </div>
      ):(
        <div className='money-filefull'>
        <div className='money-text'>
          <img
            src="https://fusionbucket.co.uk/My-account/arrowwhitecolor.png"
            alt="arrow-icon"
            className='arrow-icons'
            onClick={() => navigate('/myaccount')}
          />
          <h4 className='money-htag'>M O N E Y</h4>
        </div>
        <Container>
          <div className="money-page">
            <Row className='card-moneypage'>
              <Col xs={7}>
                <p className='card-textptag'>
                  Fusion Kitchen For <br />
                  <span className='card-spantet'>Enterprises</span>
                  <br /> Simplifying Food Ordering For business
                </p>
              </Col>
              <Col xs={5} className='wallet-image'>
                <img
                  src="https://fusionbucket.co.uk/My-account/walletimage.png"
                  alt="wallet-image"
                />
              </Col>
            </Row>
            <Row className='Fusion-card'>
              <Col xs={9}>
                <h6 className='ptag-money'>
                  <img
                    src="https://fusionbucket.co.uk/My-account/walletimage2.png"
                    alt=""
                  />{" "}
                  Fusion Kitchen Money
                </h6>
                <p className='ptag-verify'>View Mobile Fusion Money Balance</p>
              </Col>
              <Col className='link-text' xs={3}>
                <h4>£ {walletdashboard?.status ? walletdashboardamount : 0}</h4>
              </Col>
            </Row>
          </div>
          <div className="line-container">
            <div className="line"></div>
            <div className="text">W A L L E T</div>
            <div className="line"></div>
          </div>
          <div className='click-payment'>
            <Row className='click-payment1'>
              <Col xs={7}>
                <h4 className='htagfusion-wallet'>
                  Fusion <br />
                  Wallet
                </h4>
                <p className='ptagfusion-wallet'>Single Click payment</p>
              </Col>
              <Col xs={5}>
                <img
                  src="https://fusionbucket.co.uk/My-account/wallet with bank cards.png"
                  alt="wallet-with-bank"
                  className='image-col'
                />
              </Col>
            </Row>
            <p className='create-text' onClick={() => navigate('/savedcards')}>
              View Cards{" "}
              <img
                src="https://fusionbucket.co.uk/My-account/Vector.png"
                alt="vector-small"
              />
            </p>
          </div>
          <div className="line-container">
            <div className="line"></div>
            <div className="text">H I S T O R Y  C A R D</div>
            <div className="line"></div>
          </div>
          <div className='click-payment'>
            <Row className='Gift-cards'>
              <Col xs={7}>
                <h4 className='htagfusion-wallet'>History</h4>
                <p className='ptagfusion-wallet'>Buy & Share Instantly 4 Years Validity</p>
              </Col>
              <Col xs={5}>
                <img
                  src="/static/media/wallethistory.041d5ac14ed1244db208819a74d41381.svg"
                  alt="wallet-with-bank"
                  className='image-col'
                />
              </Col>
            </Row>
            <p className='create-text' onClick={() => navigate('/wallethistory')}>
              View History{" "}
              <img
                src="https://fusionbucket.co.uk/My-account/Vector.png"
                alt="vector-small"
              />
            </p>
          </div>
          {/* <div className="line-container">
            <div className="line"></div>
            <div className="text">P A Y M E N T S</div>
            <div className="line"></div>
          </div> */}
          
        </Container>
      </div>
      )}
     
    </>
  );
};

const mapStateToProps = (state) => ({
  walletdashboard: state.walletdashboard.walletdashboard,
  userData: state.userdata.userData,
});

const mapDispatchToProps = {
  getWalletDashboard,
};

export default connect(mapStateToProps, mapDispatchToProps)(MoneyPage);
