import React, { useState, useEffect, useContext } from "react";
import './Yourorder.scss';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import SearchIcon from '@mui/icons-material/Search';
import { Container, Button } from 'react-bootstrap';
import { Link, useNavigate, useLocation } from "react-router-dom";
import ThemeContext from "../../../../common/theme/components/contexts/themecontexts";
import { connect } from "react-redux";
import { getOrderHistory } from "../../../../actions/myaccount/order/orderHistoryActions";
import { orderAgainApi } from "../../../../api/myaccount/order/orderAgainApi";
import OrderDetailJson from "../myorder/orderdetails.json";
import {
  MdOutlineArrowBackIos,
  MdOutlineKeyboardArrowDown,
} from "react-icons/md";

export const reOrder = async (formData) => {
  try {
    const response = await orderAgainApi(formData);
    return response.data;
  } catch (error) {
    console.log(error);
    throw error;
  }
};

const Yourorder = ({
  orderhistory,
  orderagain,
  error,
  userData,
  getOrderHistory,
}) => {

  const { theme } = useContext(ThemeContext);
  const Imagetopdark = 'https://fusionbucket.co.uk/My-account/arrowwhitecolor.png';
  const Imagetop = 'https://fusionbucket.co.uk/BlogImages/My-accoount-arrow.png';

  const [searchOrder, setSearchOrder] = useState(null);
  const [width, setWidth] = useState(window.innerWidth);
  const breakpoint = 992;
  const location = useLocation();
  const canonicalUrl = `${window.location.origin}${location.pathname}`;

  useEffect(() => {
    const handleResizeWindow = () => setWidth(window.innerWidth);
    window.addEventListener("resize", handleResizeWindow);
    return () => {
      window.removeEventListener("resize", handleResizeWindow);
    };
  }, []);


  const OrderDetails = OrderDetailJson && OrderDetailJson;

  const OrderDetailURL = "../orderdetail";
  const navigate = useNavigate();
  const navigateToOrderDetail = async (event) => {
    const GetOId = event.target.getAttribute("Oid");
    const GetCId = event.target.getAttribute("Cid");
    const GetPath = event.target.getAttribute("Path");
    navigate(OrderDetailURL, { state: { GetOId, GetCId, GetPath } });
  };

  const OrderStatusURL = "../orderstatus";
  const navigateToOrderStatus = (event) => {
    const GetOId = event.target.getAttribute("Oid");
    const GetCId = event.target.getAttribute("Cid");
    navigate(OrderStatusURL, { state: { GetOId, GetCId } });
  };

  // Responsive Navigate
  const navigateto = useNavigate();
  if (!location.pathname.includes("/myaccount") && width > breakpoint) {
    navigateto("/myaccount/myorder");
  }

  // get the data from API
  useEffect(() => {
    getOrderHistory({ customer_id: userData.customerId, body: "Your body" });
  }, []);

  if (error) {
    return <div>Error: {error}</div>;
  }

  const orderhistorydatas = orderhistory?.message; // Assign to a separate variable
  const handleOrderAgain = (orderId, client_id, clientPath) => {
    const formData = {
      client_id: client_id,
      order_id: orderId,
    };
    console.log(formData);
    reOrder(formData)
      .then((response) => {
        console.log(response);
        if (response.status === false) {
          console.log(response.message);
        } else {
          const orderAgainData = JSON.stringify(response?.message?.item);
          localStorage.setItem("cart", orderAgainData);
          const totalPrice = response?.message?.item.reduce(
            (sum, item) => sum + parseFloat(item.price),
            0
          );
          const totalCount = response?.message?.item.reduce(
            (sum, item) => sum + parseFloat(item.count),
            0
          );
          localStorage.setItem("cartQty", totalCount);
          localStorage.setItem("totalPrice", totalPrice);
          localStorage.setItem("cartClientPath", clientPath);
          navigate(`/${clientPath}/menu`);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };



  return (
    <section>
      {width > breakpoint ? (<>
        <div className='yourorder-fk'>
          <Container>
            <div>
           
              <h5 className='arrow-tablebooking-text'>
              <img src={theme === "dark" ? Imagetopdark : Imagetop} onClick={() => navigate('/myaccount')} alt="arrow-iconyourorder" className="arrow-iconyourorder" />
                Order History
              </h5>
            </div>
            <div className='textfield-search'>
              <TextField
                className='search-feild'
                variant="outlined"
                size='small'
                placeholder="Search For Takeawayname..."
                onChange={(e) => setSearchOrder(e.target.value)}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon className='searchicon' />
                    </InputAdornment>
                  ),
                }}
              />
            </div>
            <div className="row">

              {orderhistorydatas?.filter((v) =>
                searchOrder
                  ? v?.takeawayName?.toString()?.toLowerCase()?.includes(searchOrder.toLowerCase())
                  : true
              )?.map((OrderDetail, index) => (
                <div className="col-6">
                  <div key={index} className='card-order'>
                    <div className="order-container">
                      <h6 className="order-header">
                        <img
                          src={OrderDetail.logo}
                          alt="order-image"
                          className='order-imagefirst'
                        />
                        <span className="restaurant-name">{OrderDetail.takeawayName}</span>
                        <span className='waiting-messagebutn'>{OrderDetail.orderDeliveryStatus} </span>
                      </h6>
                      <p className='ptag-order'>
                        {/* {order.location} {order.deliveryTime} */}

                      </p>
                    </div>
                    {/* <div className='item-data'>
                {order.items.map((item, idx) => (
                  <h5 key={idx}  >
                    <img src="https://fusionbucket.co.uk/My-account/plyconnew.png" alt="ploycon-icon" className='ploycon-image' />
                    {item.name}
                  </h5>
                ))}
              </div> */}
                    <h6 className='order-data'>{OrderDetail?.orderedDate} <span className='amount-color'>£ {OrderDetail?.orderTotalAmount}</span>
                    </h6>
                    <div className='row'>
                      <div className="col-6" >
                        <Button className='button-viewbill' Oid={OrderDetail?.orderId}
                          Cid={OrderDetail?.clientId}
                          Path={OrderDetail?.clientPath}
                          onClick={navigateToOrderDetail}
                        >View Bill</Button>
                      </div>
                      <div className="col-6">
                        {/* <Button className='button-reorder'>
                <img src="https://fusionbucket.co.uk/My-account/refresh.image.png" alt="reorder-image" className='reorder-image' /> Reorder
              </Button> */}
                        {OrderDetail.orderStatus == "reorder" ? (
                          <>
                            <Button
                              className='button-reorder'
                              // to={OrderDetail.ViewStatus}
                              onClick={() =>
                                handleOrderAgain(
                                  OrderDetail.orderId,
                                  OrderDetail.clientId,
                                  OrderDetail.clientPath
                                )
                              }
                            >
                              {/* <img src="https://fusionbucket.co.uk/My-account/refresh.image.png" alt="reorder-image" className='reorder-image' /> */}
                              Reorder
                            </Button>
                          </>
                        ) : (
                          <>
                            <Button
                              className="button-reorder"
                              Oid={OrderDetail.orderId}
                              Cid={OrderDetail.clientId}
                              onClick={navigateToOrderStatus}
                            >
                              Status
                            </Button>
                          </>
                        )}
                      </div>
                    </div>
                  </div>
                </div>

              ))}
            </div>
          </Container>
        </div>
      </>) : (
        <>
          <div className='yourorder-fk'>
            <Container>
              <div>
                <h5 className='arrow-tablebooking-text'>
                  <MdOutlineArrowBackIos className='arrowtop-icon' onClick={() => navigate('/myaccount')} />
                  {/* <img src={theme === "dark" ? Imagetopdark : Imagetop} className='arrow-icon' onClick={handlemyaccountclick} /> */}
                  Order History
                </h5>
              </div>
              <div className='textfield-search'>
                <TextField
                  className='search-feild'
                  variant="outlined"
                  size='small'
                  placeholder="Search For Area ,Street name..."
                  onChange={(e) => setSearchOrder(e.target.value)}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <SearchIcon className='searchicon' />
                      </InputAdornment>
                    ),
                  }}
                />
              </div>
              {orderhistorydatas?.filter((v) =>
                searchOrder
                  ? v?.takeawayName?.toString()?.toLowerCase()?.includes(searchOrder.toLowerCase())
                  : true
              )?.map((OrderDetail, index) => (
                <div key={index} className='card-order'>
                  <div className="order-container">
                    <h6 className="order-header">
                      <img
                        src={OrderDetail.logo}
                        alt="order-image"
                        className='order-imagefirst'
                      />
                      <span className="restaurant-name">{OrderDetail.takeawayName}</span>
                      <span className='waiting-messagebutn'> {OrderDetail.orderDeliveryStatus}</span>
                    </h6>
                    <p className='ptag-order'>
                      {/* {order.location} {order.deliveryTime} */}

                    </p>
                  </div>
                  {/* <div className='item-data'>
                {order.items.map((item, idx) => (
                  <h5 key={idx}  >
                    <img src="https://fusionbucket.co.uk/My-account/plyconnew.png" alt="ploycon-icon" className='ploycon-image' />
                    {item.name}
                  </h5>
                ))}
              </div> */}
                  <h6 className='order-data'>{OrderDetail?.orderedDate} <span className='amount-color'>£ {OrderDetail?.orderTotalAmount}</span>
                    <span className='view-menutxt' Oid={OrderDetail?.orderId}
                      Cid={OrderDetail?.clientId}
                      Path={OrderDetail?.clientPath}
                      onClick={navigateToOrderDetail}
                    >View Bill</span></h6>
                  <div className='buttonorder-divbtn'>
                    {/* <Button className='button-reorder'>
                <img src="https://fusionbucket.co.uk/My-account/refresh.image.png" alt="reorder-image" className='reorder-image' /> Reorder
              </Button> */}
                    {OrderDetail.orderStatus == "reorder" ? (
                      <>
                        <Button
                          className='button-reorder'
                          // to={OrderDetail.ViewStatus}
                          onClick={() =>
                            handleOrderAgain(
                              OrderDetail.orderId,
                              OrderDetail.clientId,
                              OrderDetail.clientPath
                            )
                          }
                        >
                          <img src="https://fusionbucket.co.uk/My-account/refresh.image.png" alt="reorder-image" className='reorder-image' />
                          Reorder
                        </Button>
                      </>
                    ) : (
                      <>
                        <Button
                          className="button-reorder"
                          Oid={OrderDetail.orderId}
                          Cid={OrderDetail.clientId}
                          onClick={navigateToOrderStatus}
                        >
                          View Status
                        </Button>
                      </>
                    )}
                  </div>
                </div>
              ))}
            </Container>
          </div>
        </>
      )}

    </section>
  );
};

const mapStateToProps = (state) => ({
  orderhistory: state.orderhistory.orderhistory,
  userData: state.userdata.userData,
  orderagain: state.orderagain.orderagain,
});

const mapDispatchToProps = {
  getOrderHistory,
};

export default connect(mapStateToProps, mapDispatchToProps)(Yourorder);
