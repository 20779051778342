// *******~ Import ~******** //
// React
import React, { useState, useEffect, useContext } from "react";
import { Helmet } from "react-helmet";
// Assets
import { useNavigate, useLocation } from "react-router-dom";
import { Container, Row, Col, Table, Image } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
import { TextField } from '@mui/material';
// Components
//import AddressDatas from "./addressdata.json";
import BackBtn from "../backbtn";
import ThemeContext from "../../../../common/theme/components/contexts/themecontexts";
// CSS
import "../addressbook/css/addressbook.scss";
import "../addressbook/css/addressmodal.scss";
import "../addressbook/css/addressbookV2.scss";
// Images
import HomeIcon from "../addressbook/img/home-a.svg";
import OfficeIcon from "../addressbook/img/office-ad.svg";
import OtherIcon from "../addressbook/img/other-ad.svg";
import AddNewAddImg from "../addressbook/img/add-new-add.svg";
// Icons
import { FaEdit } from "react-icons/fa";
import { BsFillPlusCircleFill } from "react-icons/bs";
import { MdCancel } from "react-icons/md";
import {
  MdOutlineArrowBackIos,
  MdOutlineKeyboardArrowDown,
} from "react-icons/md";
// *******~ Import ~******** //

// Redux wallet history
import { connect } from "react-redux";
import { getAddress } from "../../../../actions/myaccount/address/getAddressActions";
import { addAddress } from "../../../../actions/myaccount/address/addAddressActions";
import { updateAddress } from "../../../../actions/myaccount/address/updateAddressActions";
import { updatePrimaryAddress } from "../../../../actions/myaccount/address/updatePrimaryAddressActions";
import { deleteAddress } from "../../../../actions/myaccount/address/deleteAddressActions";
//validation schema
import { Formik, useFormik } from "formik";
import * as Yup from "yup";
const AddressBook = ({
  address,
  deleteaddress,
  error,
  userData,
  getAddress,
  updateaddress,
  updateprimaryaddress,
  addAddress,
  updateAddress,
  deleteAddress,
  updatePrimaryAddress,
}) => {
  const location = useLocation();
  const canonicalUrl = `${window.location.origin}${location.pathname}`;
  const [width, setWidth] = useState(window.innerWidth);
  const breakpoint = 767;
  const [addType, setAddType] = useState(0);
  useEffect(() => {
    const handleResizeWindow = () => setWidth(window.innerWidth);
    // subscribe to window resize event "onComponentDidMount"
    window.addEventListener("resize", handleResizeWindow);
    return () => {
      // unsubscribe "onComponentDestroy"
      window.removeEventListener("resize", handleResizeWindow);
    };
  }, []);

  const [activeIndex, setActiveIndex] = useState(0);

  const handleOnClick = (index) => {
    setActiveIndex(index); // remove the curly braces
  };
  // location change on responsive
  const navigateto = useNavigate();
  if (!location.pathname?.includes("/myaccount") && width > breakpoint) {
    navigateto("/myaccount/addressaccount");
  }
  // location change on responsive

  // get the data from API
  useEffect(() => {
    getAddress({ customer_id: userData.customerId, body: "Your body" });
  }, []);

  if (error) {
    return <div>Error: {error}</div>;
  }

  // Handle loading statehandleAddres
  // if (!address && !error) {
  //   return <div>Loading...</div>;
  // }

  const AddressDatas = address?.data; // Assign to a separate variable
  const AddressDatasMap =
    AddressDatas && AddressDatas?.map((AddressData, index) => AddressData.type);

  // console.log(AddressDatas, AddressDatasMap);
  const handleAddressUpdate = (index, updatedData) => {
    updateAddress(updatedData);
  };

  const handleAddressDelete = (index, deletedData) => {
    deleteAddress({ address_id: deletedData["address_id"] });
  };
  const handleAddressPrimary = (index, primaryData) => {
    updatePrimaryAddress({
      customer_id: userData.customerId,
      address_type: primaryData["address_type"],
    });
  };

  const handleAddressAdd = (addData) => {
    addData["customer_id"] = userData.customerId;
    addData["primary"] = 0;
    setAddType();
    addAddress(addData);
  };

  return (
    <>
      <Helmet>
        <style type="text/css">{`
    footer{
      display:none;
    }
    
  `}</style>
      </Helmet>
      <Helmet>
        <link rel="canonical" href={canonicalUrl} />
        <title>Fusion Kitchen | Address Book</title>
      </Helmet>
      <section className="address-book">
        {width > breakpoint ? (
          <>
            <Container>
              {AddressDatasMap?.includes(1) ? (
                AddressDatasMap?.includes(2) ? (
                  AddressDatasMap?.includes(3) ? null : (
                    <AddNewButton
                      buttonval={AddressDatasMap}
                      onAdd={(addData) => handleAddressAdd(addData)}
                      userData={userData}
                      addType={AddressDatas}
                    />
                  )
                ) : (
                  <AddNewButton
                    buttonval={AddressDatasMap}
                    onAdd={(addData) => handleAddressAdd(addData)}
                    userData={userData}
                    addType={AddressDatas}
                  />
                )
              ) : (
                <AddNewButton
                  buttonval={AddressDatasMap}
                  onAdd={(addData) => handleAddressAdd(addData)}
                  userData={userData}
                  addType={AddressDatas}
                />
              )}
              <Row>
                {AddressDatas && AddressDatas?.length === 0 ? (
                  <>
                    <NoAddress />
                  </>
                ) : (
                  <>
                    <div className="line-container">
                      <div className="line"></div>
                      <div className="text">SAVED ADDRESS</div>
                      <div className="line"></div>
                    </div>
                    {AddressDatas &&
                      AddressDatas.map((data, index) => (
                        <>
                          <Col xs={12} sm={12} md={12} lg={12} xl={12} xxl={12}>
                            <div className="address-box">
                              <Table responsive>
                                <tbody>
                                  <tr>
                                    <td rowSpan={2}>
                                      <div className="img-boxs">
                                        <EditAddress
                                          AddrType={data.type}
                                          primarybtn={() =>
                                            handleOnClick(index)
                                          }
                                          primaryText={
                                            data.primary_address == "1"
                                              ? "Primary"
                                              : "Set as Primary"
                                          }
                                          primarybtnCls={
                                            data.primary_address == "1"
                                              ? "primary-btn active"
                                              : "primary-btn"
                                          }
                                          fisrtname={data.fname}
                                          lastname={data.lname}
                                          phone={data.phone}
                                          postcode={data.postcode}
                                          doornum={data.no}
                                          street={data.address1}
                                          city={data.address2}
                                          addressid={data.id}
                                          primaryStatus={
                                            data.primary_address == "1" ? 1 : 0
                                          }
                                          onUpdate={(updatedData) =>
                                            handleAddressUpdate(
                                              index,
                                              updatedData
                                            )
                                          }
                                          onDelete={(deletedData) =>
                                            handleAddressDelete(
                                              index,
                                              deletedData
                                            )
                                          }
                                          onPrimary={(primaryData) =>
                                            handleAddressPrimary(
                                              index,
                                              primaryData
                                            )
                                          }
                                        />
                                      </div>
                                      {data.primary_address == "1" ? (
                                        <>
                                          <span
                                            className={
                                              data.primary_address == "1"
                                                ? "primary-btn active"
                                                : "primary-btn"
                                            }
                                          >
                                            Primary
                                          </span>
                                        </>
                                      ) : null}
                                    </td>

                                    <td>
                                      <div className="text-group">
                                        <span>First Name</span>
                                        <p>{data.fname}</p>
                                      </div>
                                    </td>
                                    <td>
                                      <div className="text-group">
                                        <span>Last Name</span>
                                        <p>{data.lname}</p>
                                      </div>
                                    </td>
                                    <td>
                                      <div className="text-group">
                                        <span>Telephone/Mobile</span>
                                        <p>{data.phone}</p>
                                      </div>
                                    </td>
                                  </tr>
                                  <tr>
                                    <td>
                                      <div className="text-group">
                                        <span>Post Code</span>
                                        <p>{data.postcode}</p>
                                      </div>
                                    </td>
                                    <td>
                                      <div className="text-group">
                                        <span>Street</span>
                                        <p>{data.address1}</p>
                                      </div>
                                    </td>
                                    <td>
                                      <div className="text-group">
                                        <span>City</span>
                                        <p>{data.address2}</p>
                                      </div>
                                    </td>
                                  </tr>
                                </tbody>
                              </Table>
                            </div>
                          </Col>
                        </>
                      ))}
                  </>
                )}
              </Row>
            </Container>
          </>
        ) : (
          <>
            <Container>
              {/* <BackBtn /> */}
              {AddressDatasMap?.includes(1) ? (
                AddressDatasMap?.includes(2) ? (
                  AddressDatasMap?.includes(3) ? null : (
                    <AddNewButton
                      buttonval={AddressDatasMap}
                      onAdd={(addData) => handleAddressAdd(addData)}
                      userData={userData}
                      addType={AddressDatas}
                    />
                  )
                ) : (
                  <AddNewButton
                    buttonval={AddressDatasMap}
                    onAdd={(addData) => handleAddressAdd(addData)}
                    userData={userData}
                    addType={AddressDatas}
                  />
                )
              ) : (
                <AddNewButton
                  buttonval={AddressDatasMap}
                  onAdd={(addData) => handleAddressAdd(addData)}
                  userData={userData}
                  addType={AddressDatas}
                />
              )}

              {AddressDatas && AddressDatas.length === 0 ? (
                <>
                  <NoAddress />
                </>
              ) : (
                <>
                  <Row>
                    <div className="line-container">
                      <div className="line"></div>
                      <div className="text">SAVED ADDRESS</div>
                      <div className="line"></div>
                    </div>
                    {AddressDatas &&
                      AddressDatas?.map((data, index) => (
                        <>

                          <Col xxl={12}>
                            <div className="address-icon">
                              {/* <div className="heading-box">
                                <div className="img-box"> */}

                              <EditAddress
                                AddrType={data.type}
                                primarybtn={() => handleOnClick(index)}
                                primaryText={
                                  data.primary_address == "1"
                                    ? "Primary"
                                    : "Set as Primary"
                                }
                                primarybtnCls={
                                  data.primary_address == "1"
                                    ? "primary-btn active"
                                    : "primary-btn"
                                }
                                primaryStatus={
                                  data.primary_address == "1" ? 1 : 0
                                }
                                fisrtname={data.fname}
                                lastname={data.lname}
                                phone={data.phone}
                                postcode={data.postcode}
                                doornum={data.no}
                                street={data.address1}
                                city={data.address2}
                                addressid={data.id}
                                onUpdate={(updatedData) =>
                                  handleAddressUpdate(index, updatedData)
                                }
                                onDelete={(deletedData) =>
                                  handleAddressDelete(index, deletedData)
                                }
                                onPrimary={(primaryData) =>
                                  handleAddressPrimary(index, primaryData)
                                }
                              />
                              {data.primary_address == "1" ? (
                                <>
                                  <span
                                        className={
                                          data.primary_address == "1"
                                            ? "primary-btn active"
                                            : "primary-btn"
                                        }
                                      >
                                        Primary
                                      </span>
                                </>
                              ) : null}
                              {/* </div> */}
                              <div className="name-number">
                                <p className="address-namenumber">{data.fname + " " + data.lname}   {data.postcode}   {data.no + ", " + data.address1}   <br />
                                  {data.address2}</p>
                                <p className="address-numberupdate">Mobile: {data.phone}</p>
                              </div>
                              {/* </div> */}

                              <div className="address-div"></div>
                            </div>
                          </Col>
                        </>
                      ))}
                  </Row>
                </>
              )}


            </Container>
          </>
        )}
      </section>
    </>
  );
};

const mapStateToProps = (state) => ({
  address: state.address.address, // Replace 'post' with your reducer name
  userData: state.userdata.userData,
  addaddress: state.addaddress.addaddress,
  deleteaddress: state.deleteaddress.deleteaddress,
  updateaddress: state.updateaddress.updateaddress,
  updateprimaryaddress: state.updateprimaryaddress.updateprimaryaddress,
});

const mapDispatchToProps = {
  getAddress,
  addAddress,
  updateAddress,
  deleteAddress,
  updatePrimaryAddress,
};

export default connect(mapStateToProps, mapDispatchToProps)(AddressBook);

const AddNewButton = (props) => {
  return (
    <>
      <>
        <Row>
          <Col xxl={12}>
            <div >
              <AddAddressForm
                buttonval={props.buttonval}
                onAdd={(addData) => props.onAdd(addData)}
                userData={props.userData}
                addType={props.addType}
                className='address-module'
              />
            </div>
          </Col>
        </Row>
      </>
    </>
  );
};

const NoAddress = (params) => {
  return (
    <>
      <Col xxl={12}>
        <div className="no-address-img">
          <Image src={AddNewAddImg} fluid />
          <h3> Oops! None of your Address saved yet.</h3>
        </div>
      </Col>
    </>
  );
};

function EditAddress(props) {
  const { theme } = useContext(ThemeContext);
  const [show, setShow] = useState(false);

  const handleShow = () => setShow(true);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768)
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const [updatedData, setUpdatedData] = useState({
    first_name: props.fisrtname,
    last_name: props.lastname,
    phone_number: props.phone,
    postcode: props.postcode,
    city: props.city,
    street: props.street,
    door: props.doornum,
    address_type: props.AddrType,
    address_id: props.addressid,
    primary: props.primaryStatus,
  });
  const handleClose = () => {
    setShow(false);
    setUpdatedData(updatedData);
  };
  const handleUpdate = () => {
    const updateDataCheck = Object.values(updatedData).some((v) => v === "");
    if (!updateDataCheck) {
      props.onUpdate(updatedData); // Callback to send updated data
      handleClose();
    }
  };
  const handleDelete = () => {
    props.onDelete(updatedData); // Callback to send deleted data
    handleClose();
  };
  const handlePrimary = () => {
    props.onPrimary(updatedData); // Callback to send primary data
    handleClose();
  };
  const handleInputChange = (field, value) => {
    setUpdatedData((prevData) => ({
      ...prevData,
      [field]: value,
    }));
  };

  //trigger Update
  const handleUpdateTrigger = () => {
    document.getElementById("updateTrigerBtn").click();
  };


  return (
    <>

      {props.AddrType && props.AddrType == 1 ? (
        <>
          <p type="button" onClick={handleShow}>
            <Image src={HomeIcon} fluid />
            <span className="home-address"> Home</span>
            <FaEdit />
          </p>
        </>
      ) : props.AddrType == 2 ? (
        <>

          <p type="button" class="address-type office" onClick={handleShow}>
            <Image src={OfficeIcon} fluid />
            <span className="home-address">Office</span>
            <FaEdit />
          </p>
        </>
      ) : props.AddrType == 3 ? (
        <>

          <p type="button" class="address-type other" onClick={handleShow}>
            <Image src={OtherIcon} fluid />
            <span className="home-address">  Others</span>
            <FaEdit />
          </p>
        </>
      ) : (
        ""
      )}

      <Modal
        show={show}
        onHide={handleClose}
        id="addr-book-modal"

        keyboard={false}
        // dialogClassName={`bottom-modaladdress ${theme === "dark" ? "theme--dark" : ""} ${isMobile ? "show-mobile" : "show-desktop"}`}
        className={theme === "dark" ? "dark-popup" : null}
      >
        <Modal.Header>
          <Modal.Title>
            Edit{" "}
            {props.AddrType && props.AddrType == 1
              ? "Home "
              : props.AddrType == 2
                ? "Office "
                : props.AddrType == 3
                  ? "Others "
                  : ""}{" "}
            Address
          </Modal.Title>
          <Button className="close-btn" onClick={handleClose}>
            <MdCancel />
          </Button>
        </Modal.Header>

        <Modal.Body>
          <Form>
            <Row className="align-items-end">
              <Col md={6}>
                <div className={theme === "dark" ? " textinputweightdark" : "textinputweight"}>
                  <TextField
                    margin="normal"
                    variant="outlined"
                    size="small"
                    fullWidth
                    label="First Name"
                    type="text"
                    placeholder={
                      updatedData.first_name === ""
                        ? "First Name-mandatory"
                        : "First Name"
                    }

                    value={updatedData.first_name}
                    className={
                      updatedData.first_name === "" ? "is-invalid" : ""
                    }
                    onChange={(e) =>
                      handleInputChange("first_name", e.target.value)
                    }
                  />
                </div>
              </Col>
              <Col md={6}>
                <div className={theme === "dark" ? " textinputweightdark" : "textinputweight"}>
                  <TextField
                    margin="normal"
                    variant="outlined"
                    size="small"
                    label="Last Name"
                    type="text"
                    fullWidth
                    placeholder={
                      updatedData.last_name === ""
                        ? "Last Name-mandatory"
                        : "Last Name"
                    }
                    className={updatedData.last_name === "" ? "is-invalid" : ""}

                    value={updatedData.last_name}
                    onChange={(e) =>
                      handleInputChange("last_name", e.target.value)
                    }
                  />
                </div>
              </Col>
              <Col md={6}>
                <div className={theme === "dark" ? " textinputweightdark" : "textinputweight"}>
                  <TextField
                    margin="normal"
                    variant="outlined"
                    size="small"
                    label="Mobile"
                    type="number"
                    fullWidth
                    placeholder={
                      updatedData.phone_number === ""
                        ? "Phone Number-mandatory"
                        : "Phone Number"
                    }
                    className={
                      updatedData.phone_number === "" ? "is-invalid" : ""
                    }
                    value={updatedData.phone_number}
                    onChange={(e) =>
                      handleInputChange("phone_number", e.target.value)
                    }
                  />
                </div>
              </Col>
              <Col md={6}>
                <div className={theme === "dark" ? " textinputweightdark" : "textinputweight"}>
                  <TextField
                    margin="normal"
                    variant="outlined"
                    size="small"
                    label="Post Code"
                    type="text"
                    fullWidth
                    placeholder={
                      updatedData.postcode === ""
                        ? "Post Code-mandatory"
                        : "Post Code"
                    }
                    className={updatedData.postcode === "" ? "is-invalid" : ""}

                    value={updatedData.postcode}
                    onChange={(e) =>
                      handleInputChange("postcode", e.target.value)
                    }
                  />
                </div>
              </Col>
              <Col md={6}>
                <div className={theme === "dark" ? " textinputweightdark" : "textinputweight"}>
                  <TextField
                    margin="normal"
                    variant="outlined"
                    size="small"
                    label="Door Number"
                    type="text"
                    fullWidth
                    placeholder={
                      updatedData.door === ""
                        ? "Door Number-mandatory"
                        : "Door Number"
                    }
                    className={updatedData.door === "" ? "is-invalid" : ""}

                    value={updatedData.door}
                    onChange={(e) => handleInputChange("door", e.target.value)}
                  />
                </div>
              </Col>
              <Col md={6}>
                <div className={theme === "dark" ? " textinputweightdark" : "textinputweight"}>
                  <TextField
                    margin="normal"
                    variant="outlined"
                    size="small"
                    label="Street"
                    fullWidth
                    type="text"
                    placeholder={
                      updatedData.street === "" ? "Street-mandatory" : "Street"
                    }
                    className={updatedData.street === "" ? "is-invalid" : ""}

                    value={updatedData.street}
                    onChange={(e) =>
                      handleInputChange("street", e.target.value)
                    }
                  />
                </div>
              </Col>
              <Col md={6}>
                <div className={theme === "dark" ? " textinputweightdark" : "textinputweight"}>
                  <TextField
                    margin="normal"
                    variant="outlined"
                    size="small"
                    label="City"
                    type="text"
                    fullWidth
                    placeholder={
                      updatedData.city === "" ? "City-mandatory" : "City"
                    }
                    className={updatedData.city === "" ? "is-invalid" : ""}

                    value={updatedData.city}
                    onChange={(e) => handleInputChange("city", e.target.value)}
                  />
                </div>
              </Col>
              <Col md={6}>
                <Button
                  className="update-btn d-none"
                  id="updateTrigerBtn"
                  onClick={handleUpdate}
                >
                  Update
                </Button>
              </Col>
            </Row>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button className="update-btnv2" onClick={handleUpdateTrigger}>
            Update
          </Button>
          <Button className="delete-btnv2" onClick={handleDelete}>
            Delete
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

function AddAddressForm(props) {
  const { theme } = useContext(ThemeContext);
  const [show, setShow] = useState(false);

  const handleShow = () => setShow(true);
  // console.log(String(props.buttonval));
  const addressType = props?.addType?.type || 0;
  const [addData, setAddData] = useState({
    first_name: "",
    last_name: "",
    postcode: "",
    phone_number: "",
    city: "",
    street: "",
    door: "",
    customer_id: "",
    address_type: "",
    primary: "",
  });
  const handleClose = () => {
    setAddData({
      first_name: "",
      last_name: "",
      postcode: "",
      phone_number: "",
      city: "",
      street: "",
      door: "",
      customer_id: "",
      address_type: "",
      primary: "",
    });
    setShow(false);
  };

  const handleInputAddChange = (field, value) => {
    if (field === "address_type") {
      setAddData((prevData) => ({
        ...prevData,
        address_type: value,
      }));
    } else {
      setAddData((prevData) => ({
        ...prevData,
        [field]: value,
      }));
    }
  };
  // useEffect(() => {
  //   console.log(addData);
  // }, [addData]);
  //trigger
  const handleAddress = () => {
    document.getElementById("triger-btn").click();
  };
  const handleSet = (v) => {
    v["customer_id"] = props.userData.customer_id;
    v["primary"] = "0";
    handleAddAddress(v);
  };
  const handleAddAddress = (value) => {
    // console.log(addData);
    props.onAdd(value); // Callback to send updated data
    handleClose();
  };

  const validationSchema = Yup.object().shape({
    first_name: Yup.string().required("First Name is required"),
    last_name: Yup.string().required("Last Name is required"),
    phone_number: Yup.string().required("Phone Number is required"),
    postcode: Yup.string().required("Post Code is required"),
    door: Yup.string().required("Door Number is required"),
    street: Yup.string().required("Street is required"),
    city: Yup.string().required("City is required"),
    address_type: Yup.string().required("Address Type is required"),
  });
  const Imagetopdark = 'https://fusionbucket.co.uk/My-account/arrowwhitecolor.png';
  const Imagetop = 'https://fusionbucket.co.uk/Account-details /Arrow-final.png';
  const navigate = useNavigate();
  const [width, setWidth] = useState(window.innerWidth);
  const breakpoint = 767;
  useEffect(() => {
    const handleResizeWindow = () => setWidth(window.innerWidth);
    // subscribe to window resize event "onComponentDidMount"
    window.addEventListener("resize", handleResizeWindow);
    return () => {
      // unsubscribe "onComponentDestroy"
      window.removeEventListener("resize", handleResizeWindow);
    };
  }, []);
  return (
    <>
      {width > breakpoint ? (<div>
        <Container>
          <div>
            <h5 className='arrow-address-text'>
              {/* <MdOutlineArrowBackIos className='arrowtop-icon'  onClick={() => navigate('/myaccount')} /> */}
              <img src={theme === "dark" ? Imagetopdark : Imagetop} className='arrow-iconaddress' onClick={() => navigate('/myaccount')} />
              My Address
            </h5>
          </div>
          <div className="payments-order" onClick={handleShow}>
            <div className="food-payments">
              <div className="img-foodorder">
                <img src="https://fusionbucket.co.uk/My-account/addressaddonicon.png" alt="group-icon" className="addressaddicon" />
              </div>
              <div className="text-content">
                <span className="address-tittle"> Add Address </span><br />
              </div>
              <img src="https://fusionbucket.co.uk/BlogImages/Arrow-vector.png" alt="arrowdown" />
            </div>
          </div>
        </Container>
      </div>) : (<div>
        <Container>
          <div>
            <h5 className='arrow-address-text'>
              <MdOutlineArrowBackIos className='arrowtop-icon' onClick={() => navigate('/myaccount')} />
              {/* <img src={theme === "dark" ? Imagetopdark : Imagetop} className='arrow-icon' onClick={handlemyaccountclick} /> */}
              My Address
            </h5>
          </div>
          <div className="payments-order" onClick={handleShow}>
            <div className="food-payments">
              <div className="img-foodorder">
                <img src="https://fusionbucket.co.uk/My-account/addressaddonicon.png" alt="group-icon" className="addressaddicon" />
              </div>
              <div className="text-content">
                <span className="address-tittle"> Add Address </span><br />
              </div>
              <img src="https://fusionbucket.co.uk/BlogImages/Arrow-vector.png" alt="arrowdown" />
            </div>
          </div>
        </Container>
      </div>)}
      <></>

      <Modal
        show={show}
        onHide={handleClose}
        id="addr-book-modal"

        keyboard={false}
        // dialogClassName="bottom-modaladdress"
        className={theme === "dark" ? "dark-popup" : null}
      >
        <Modal.Header>
          <Modal.Title>Enter Complete Address </Modal.Title>
          <Button className="close-btn" onClick={handleClose}>
            <MdCancel />
          </Button>
        </Modal.Header>

        <Modal.Body>

          <Formik
            initialValues={{
              first_name: "",
              last_name: "",
              phone_number: "",
              postcode: "",
              door: "",
              street: "",
              city: "",
              address_type: "",
            }}
            validationSchema={validationSchema}
            onSubmit={(values, { setSubmitting }) => {
              alert(JSON.stringify(values));
              handleSet(values);
              setSubmitting(false);
            }}
          >
            {({
              values,
              handleChange,
              errors,
              touched,
              handleSubmit,
              isSubmitting,
            }) => (
              <Form onSubmit={handleSubmit}>
                <Col xxl={6} xs={12} sm={6} md={6} xl={6}>
                  <Col xxl={12} xs={12} sm={12} md={12} xl={12}>
                    <Form.Group className="form-group form-check-group">
                      <Form.Check
                        id="Home"
                        inline
                        label="Home"
                        name="address_type"
                        value="1"
                        type="radio"
                        disabled={props.buttonval.some(
                          (val) => String(val) === "1"
                        )}
                        onChange={handleChange}
                        isInvalid={touched.address_type && errors.address_type}
                      />
                      <Form.Check
                        id="Office"
                        inline
                        label="Office"
                        name="address_type"
                        value="2"
                        type="radio"
                        disabled={props.buttonval.some(
                          (val) => String(val) === "2"
                        )}
                        onChange={handleChange}
                        isInvalid={touched.address_type && errors.address_type}
                      />
                      <Form.Check
                        id="Other"
                        inline
                        label="Other"
                        name="address_type"
                        value="3"
                        type="radio"
                        disabled={props.buttonval.some(
                          (val) => String(val) === "3"
                        )}
                        onChange={handleChange}
                        isInvalid={touched.address_type && errors.address_type}
                      />
                    </Form.Group>
                  </Col>
                </Col>
                <Row className="align-items-end">
                  <Col md={6}>
                    <TextField

                      className={theme === "dark" ? " textinputweightdark" : "textinputweight"}
                      label="First Name"
                      fullWidth
                      margin="normal"
                      variant="outlined"
                      size="small"
                      type="text"
                      placeholder={
                        touched.first_name && errors.first_name
                          ? "First Name-Mandatory"
                          : "First Name"
                      }
                      value={values.first_name}
                      name="first_name"
                      onChange={handleChange}
                      error={touched.first_name && Boolean(errors.first_name)}
                      helperText={touched.first_name && errors.first_name}

                    />

                  </Col>
                  <Col md={6}>
                    <TextField

                      className={theme === "dark" ? " textinputweightdark" : "textinputweight"}
                      label="Last Name"
                      fullWidth
                      margin="normal"
                      variant="outlined"
                      size="small"
                      type="text"
                      placeholder={
                        touched.last_name && errors.last_name
                          ? "Last Name-Mandatory"
                          : "Last Name"
                      }
                      value={values.last_name}
                      name="last_name"
                      onChange={handleChange}
                      error={touched.last_name && Boolean(errors.last_name)}
                      helperText={touched.last_name && errors.last_name}
                    />




                  </Col>
                  <Col md={6}>
                    <TextField

                      className={theme === "dark" ? " textinputweightdark" : "textinputweight"}
                      label="Mobile Number"
                      fullWidth
                      margin="normal"
                      variant="outlined"
                      size="small"
                      type="number"
                      placeholder={
                        touched.phone_number && errors.phone_number
                          ? "Phone Number-Mandatory"
                          : "Phone Number"
                      }
                      value={values.phone_number}
                      name="phone_number"
                      onChange={handleChange}
                      error={touched.phone_number && Boolean(errors.phone_number)}
                      helperText={touched.phone_number && errors.phone_number}
                    />


                  </Col>
                  <Col md={6}>

                    <TextField

                      className={theme === "dark" ? " textinputweightdark" : "textinputweight"}
                      label="Post Code"
                      fullWidth
                      margin="normal"
                      variant="outlined"
                      size="small"
                      type="text"
                      placeholder={
                        touched.postcode && errors.postcode
                          ? "Post Code Number-Mandatory"
                          : "Post Code"
                      }
                      value={values.postcode}
                      name="postcode"
                      onChange={handleChange}
                      error={touched.postcode && Boolean(errors.postcode)}
                      helperText={touched.postcode && errors.postcode}

                    />

                  </Col>
                  <Col md={6}>
                    <TextField

                      className={theme === "dark" ? " textinputweightdark" : "textinputweight"}
                      label="Door Number"
                      fullWidth
                      margin="normal"
                      variant="outlined"
                      size="small"
                      type="text"
                      placeholder={
                        touched.door && errors.door
                          ? "Door Number Number-Mandatory"
                          : "Door Number"
                      }
                      value={values.door}
                      name="door"
                      onChange={handleChange}
                      error={touched.door && Boolean(errors.door)}
                      helperText={touched.door && errors.door}
                    />


                  </Col>
                  <Col md={6}>

                    <TextField

                      className={theme === "dark" ? " textinputweightdark" : "textinputweight"}
                      label="Street"
                      fullWidth
                      margin="normal"
                      variant="outlined"
                      size="small"
                      type="text"
                      placeholder={
                        touched.street && errors.street
                          ? "street Number Number-Mandatory"
                          : "street Number"
                      }
                      value={values.street}
                      name="street"
                      onChange={handleChange}
                      error={touched.street && Boolean(errors.street)}
                      helperText={touched.street && errors.street}
                    />

                  </Col>
                  <Col md={6} >

                    <TextField
                      className={theme === "dark" ? " textinputweightdark" : "textinputweight"}
                      label="City "
                      fullWidth
                      margin="normal"
                      variant="outlined"
                      size="small"
                      type="text"
                      placeholder={
                        touched.city && errors.city
                          ? "city Number Number-Mandatory"
                          : "city Number"
                      }
                      value={values.city}
                      name="city"
                      onChange={handleChange}
                      error={touched.city && Boolean(errors.city)}
                      helperText={touched.city && errors.city}
                    />

                  </Col>

                </Row>
                <div className="savebuttondiv">
                  <Button
                    className="save-addressbutton"
                    type="submit"
                    disabled={isSubmitting}
                    id="triger-btn"
                    onClick={handleAddAddress}
                  >
                    Save Address
                  </Button>
                </div>
              </Form>
            )}
          </Formik>
        </Modal.Body>
      </Modal>
    </>
  );
}



