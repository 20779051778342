import React, { useState, useContext, useEffect } from 'react';
import { Container, Button, Modal } from 'react-bootstrap';
import { useNavigate, useLocation } from 'react-router-dom';
import { TextField } from '@mui/material';
import { connect } from "react-redux";
import {
    MdOutlineArrowBackIos
} from "react-icons/md";
import ThemeContext from "../../../../common/theme/components/contexts/themecontexts";
import './tablebooking.scss';
import "../addressbook/css/addressmodal.scss";
import { LuClock4 } from "react-icons/lu";
import { PiCalendarCheck } from "react-icons/pi";
import { GrGroup } from "react-icons/gr";
import { ImCheckmark } from "react-icons/im";
import { FaRegCircleXmark } from "react-icons/fa6";
import { GoArrowLeft } from "react-icons/go";
import { getTableBooking } from '../../../../actions/myaccount/tablebooking/tableBookingActions';

const Tablebooking = ({ getTableBooking, tableBooking }) => {
    const breakpoint = 992;
    const location = useLocation();
    const [width, setWidth] = useState(window.innerWidth);
    const [current, setCurrent] = useState(null);
    const [show, setShow] = useState(false);
    const { theme } = useContext(ThemeContext);
    const handleClose = () => setShow(false);
    const tableBookingData = tableBooking?.message;

    const cardData = [
        {
            "id": 80,
            "firstname": "sai",
            "lastname": "sai",
            "email": "saiprasanna@fusioninnovative.com",
            "phone": "033333333333",
            "datetime": "Oct 19,2024",
            "table": 1726845480,
            "people": 2,
            "comments": "",
            "amount": "0.00",
            "order_status": "Accepted",
            "hide_status": false,
            "time": "2:30 Pm",
            "restaurentname": "Restaurent Name",
            "cusine": "Mexican",
            "discount": "10%"
        },
        {
            "id": 81,
            "firstname": "sai",
            "lastname": "sai",
            "email": "saiprasanna@fusioninnovative.com",
            "phone": "033333333333",
            "datetime": "May 19,2024",
            "table": 1726845480,
            "people": 2,
            "comments": "",
            "amount": "0.00",
            "order_status": "Accepted",
            "hide_status": false,
            "time": "2:30 Pm",
            "restaurentname": "Restaurent Name",
            "cusine": "Mexican",
            "discount": "10%"
        },
        {
            "id": 82,
            "firstname": "sai",
            "lastname": "sai",
            "email": "saiprasanna@fusioninnovative.com",
            "phone": "033333333333",
            "datetime": "Aug 23,2024",
            "table": 1726845480,
            "people": 2,
            "comments": "",
            "amount": "0.00",
            "order_status": "Placed",
            "hide_status": false,
            "time": "5:30 Pm",
            "restaurentname": "Restaurent Name",
            "cusine": "Mexican",
            "discount": "10%"
        },
        {
            "id": 83,
            "firstname": "sai",
            "lastname": "sai",
            "email": "saiprasanna@fusioninnovative.com",
            "phone": "033333333333",
            "datetime": "Sep 01,2024",
            "table": 1726845480,
            "people": 2,
            "comments": "",
            "amount": "0.00",
            "order_status": "Accepted",
            "hide_status": false,
            "time": "9:30 Am",
            "restaurentname": "Restaurent Name",
            "cusine": "Mexican",
            "discount": "10%"
        },
        {
            "id": 84,
            "firstname": "sai",
            "lastname": "sai",
            "email": "saiprasanna@fusioninnovative.com",
            "phone": "033333333333",
            "datetime": "Nov 19,2024",
            "table": 1726845480,
            "people": 2,
            "comments": "",
            "amount": "0.00",
            "order_status": "Placed",
            "hide_status": false,
            "time": "5:30 Pm",
            "restaurentname": "Restaurent Name",
            "cusine": "Mexican",
            "discount": "10%"
        },
        {
            "id": 85,
            "firstname": "sai",
            "lastname": "sai",
            "email": "saiprasanna@fusioninnovative.com",
            "phone": "033333333333",
            "datetime": "Sep 19,2024",
            "table": 1726845480,
            "people": 2,
            "comments": "",
            "amount": "0.00",
            "order_status": "Rejected",
            "hide_status": false,
            "time": "1:30 Am",
            "restaurentname": "Restaurent Name",
            "cusine": "Mexican",
            "discount": "10%"
        },
        {
            "id": 86,
            "firstname": "sai",
            "lastname": "sai",
            "email": "saiprasanna@fusioninnovative.com",
            "phone": "033333333333",
            "datetime": "Nov 19,2024",
            "table": 1726845480,
            "people": 2,
            "comments": "",
            "amount": "0.00",
            "order_status": "Accepted",
            "hide_status": false,
            "time": "8:10 Am",
            "restaurentname": "Restaurent Name",
            "cusine": "Mexican",
            "discount": "10%",
        },
        {
            "id": 87,
            "firstname": "sai",
            "lastname": "sai",
            "email": "saiprasanna@fusioninnovative.com",
            "phone": "033333333333",
            "datetime": "Sep 19,2024",
            "table": 1726845480,
            "people": 2,
            "comments": "",
            "amount": "0.00",
            "order_status": "Accepted",
            "hide_status": false,
            "time": "6:20 Am",
            "restaurentname": "Restaurent Name",
            "cusine": "Mexican",
            "discount": "10%"
        },
        {
            "id": 88,
            "firstname": "sai",
            "lastname": "sai",
            "email": "saiprasanna@fusioninnovative.com",
            "phone": "033333333333",
            "datetime": "Aug 19,2024",
            "table": 1726845480,
            "people": 2,
            "comments": "",
            "amount": "0.00",
            "order_status": "Rejected",
            "hide_status": false,
            "time": "3:00 Am",
            "restaurentname": "Restaurent Name",
            "cusine": "Mexican",
            "discount": "10%"
        }
    ];

    console.log("tableBookingData", tableBookingData);
    console.log("cardData", cardData);

    const [filter, setFilter] = useState('All');

    const filteredBookings = cardData?.filter(booking => {
        const currentDate = new Date();
        const bookingDate = new Date(booking.datetime);
        switch (filter) {
            case 'Upcoming':
                return bookingDate > currentDate && booking.order_status === 'Accepted';
            case 'Past':
                return bookingDate < currentDate && booking.order_status === 'Accepted';
            case 'Confirmation':
                return booking.order_status === 'Placed';
            case 'Cancelled':
                return booking.order_status === 'Rejected';
            default:
                return true;
        }
    });

    useEffect(() => {
        // getTableBooking({
        //     "customer_id": 153666,
        //     "path": "restaurant-demo-2-if28threefield-house-sk11",
        //     "email": "manikandan@fusioninnovative.com"
        // });
    }, []);

    const handleShow = (v) => {
        setCurrent(v);
        setShow(true);
    };
    const Imagetopdark = 'https://fusionbucket.co.uk/My-account/arrowwhitecolor.png';
    const Imagetop = 'https://fusionbucket.co.uk/BlogImages/My-accoount-arrow.png';
    const navigate = useNavigate();

    const handlemyaccountclick = () => {
        setShow(false);
    };
    const handleCancelModel = () => {
        setShow(false);
    };
    useEffect(() => {
        const handleResizeWindow = () => setWidth(window.innerWidth);
        // subscribe to window resize event "onComponentDidMount"
        window.addEventListener("resize", handleResizeWindow);
        return () => {
            // unsubscribe "onComponentDestroy"
            window.removeEventListener("resize", handleResizeWindow);
        };
    }, []);
    const navigateto = useNavigate();
    if (!location.pathname?.includes("/myaccount") && width > breakpoint) {
        navigateto("/myaccount/tablebooking");
    }
    const Modalcontenttable = () => (
        <>
            <div className='tablemodalbooking-op'>
                <h5 className='tablebooking-arrow'>
                    <MdOutlineArrowBackIos className='arrowtop-icons' onClick={handlemyaccountclick} />
                    Manage My Booking
                </h5>
                <div className='modalbooking-manage'>
                    <p className='textjaliso'>
                        Jaliso-Loghborough Junction
                        <Button className='confirmed-textbutton'>
                            <ImCheckmark className="imagefor-text order-status-icon" fill="green" />
                            <span className='order-status-msg'> Confirmed</span>
                        </Button>
                    </p>
                    <div className='modal-footertexttable'>
                        <div className='calender-div' >
                            <PiCalendarCheck
                                style={{ margin: "3px 4px 5px 3px" }}
                                className='model-tableBookingImage' />
                            <span className='model-responseDatetime'>
                                {"15 sept 2023"}
                            </span>
                        </div>
                        <div className='model-responseTimeDiv'>
                            <LuClock4 className='model-responseTime' />
                            <span style={{ margin: "5px" }}>{"11.30 Am"}</span>
                        </div>
                        <div className='model-responseGuestDiv'>
                            <GrGroup className='model-responseGuest' />
                            <span style={{ margin: "0px 0px 0px 4px", }}>
                                {/* {booking?.people} */}
                                {"6"}
                            </span>
                        </div>
                        <div className='model-discount-text'>{"10%"}</div>
                    </div>
                    <p className='button-optionstablebookingmodal'>
                        <Button className='button-callrestaurant'>
                            Call Restaurant
                        </Button>
                        {/* <Button className='button-modify'>
                            Modify
                        </Button> */}
                        <Button onClick={handleCancelModel} className='button-cancelmodal'>
                            Cancel
                        </Button>
                    </p>
                </div>
                <div className="location-card">
                    <div className="address">
                        <p>4517 Washington Ave.<br />
                            Manchester, Kentucky 39495</p>
                        <a href="https://www.google.com/maps" className="get-direction">Get Direction</a>
                    </div>
                    <div className="map">
                        <iframe
                            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2381.748478114614!2d-1.1332466841078252!3d53.95905478011268!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x487931b6c543c4a5%3A0x34ebc6f17e0a0c9a!2sLoughborough%20Junction%2C%20York%2C%20UK!5e0!3m2!1sen!2sus!4v1629972212731!5m2!1sen!2sus"
                            width="100%"
                            height="200"
                            allowFullScreen=""
                            loading="lazy"
                            title="location-map"
                        ></iframe>
                    </div>

                </div>
                <div className="special-request">
                    <label htmlFor="special-request">Special Request</label>
                    <input type="text" id="special-request" placeholder="Enter Your Message Here" />
                </div>
            </div>
        </>
    );
    const Reviewmodal = () => (
        <>
            <h4>Leave As A Review </h4>
            <TextField
                multiline
                rows={3}
                placeholder="Type Your Message Here..."
                fullWidth
                className="textfeild-review"
            />
            <Button className='Button-review'> Submit </Button>
        </>
    );
    const CustomTableCard = ({ booking }) => {
        const getStatusText = (status) => {
            switch (status) {
                case 'Accepted':
                    return (
                        <span className='confirmed-text'>
                            <ImCheckmark className="imagefor-text" fill="green" />
                            <span> Accepted</span>
                        </span>
                    );
                case 'review':
                    return (
                        <span className='imagefor-textreview' onClick={() => handleShow("Reviewmodal")}>
                            <img src="https://fusionbucket.co.uk/My-account/reviewicon.png"
                                alt="review-icon" className='imagefor-textreview' />
                            <span> Review</span>
                        </span>
                    );
                case 'Rejected':
                    return (
                        <span className='imagefor-textcancel'>
                            <FaRegCircleXmark fill="red" size="25px" className='imagefor-textcancel' />
                            <span> Rejected</span>
                        </span>
                    );
                case 'Placed':
                    return (
                        <span className='waiting-text'>
                            <img src="https://fusionbucket.co.uk/My-account/fi_66163.png"
                                alt="tick-icon" className='imagefor-text' />
                            <span>Waiting for Confirmation</span>
                        </span>
                    );
                default:
                    return null;
            }
        };

        return (
            <div className='custom-tablecard'  >
                <div className='custom-cardttable'>
                    <img
                        src={"https://fusionbucket.co.uk/My-account/Tablebookingimage.png"}
                        alt={"tablebooking"} className='image-tablebooking'
                        onClick={() => handleShow("Modalcontenttable")}
                    />
                    <div className='table-data'>
                        <p className='text-cardbox'>
                            {booking?.restaurentname}
                        </p>
                        <p className='text-cardbox'>
                            <span>
                                <img
                                    src="https://fusionbucket.co.uk/My-account/mexican foodicon.png" alt="cuisine-icon" className='imagefor-text' />
                                <span className=''>{booking?.cusine}</span>
                            </span>
                        </p>
                        <p className='text-cardbox'>
                            {getStatusText(booking?.order_status)}
                        </p>
                    </div>
                </div>
                <div className='footer-texttable'>
                    <PiCalendarCheck
                        className='tableBookingImage'
                    />
                    <span className='responseDatetime'>{booking?.datetime}</span>
                    <div className='responseTimeDiv'>
                        <LuClock4 className='responseTime' />
                        <span style={{ margin: "5px" }}>{booking?.time}</span>
                    </div>
                    <div className='responseGuestDiv'>
                        <GrGroup className='responseGuest' />
                        <span style={{ margin: "0px 0px 0px 4px", }}>{booking?.people}</span>
                    </div>
                    <div className='discount-text'>{"10%"}</div>
                </div>
            </div>
        );
    };
    useEffect(() => {
        getTableBooking({
            "customer_id": 153666,
            "path": "restaurant-demo-2-if28threefield-house-sk11",
            "email": "manikandan@fusioninnovative.com"
        })
    }, []);
    return (
        <section>
            {width > breakpoint ? (
                <>
                    <div className='yourorder-fk'>
                        <Container>
                            <div>
                                <h5 className='tablebooking-arrow'>
                                <img src={theme === "dark" ? Imagetopdark : Imagetop} className='arrow-icontablebooking' onClick={() => navigate('/myaccount')} />
                                    Table Booking
                                    {/* <Button className="booktable-button" size='small'>
                                        <img src="https://fusionbucket.co.uk/My-account/vectorplus.png" alt="booking-image" />
                                        &nbsp; Book Table
                                    </Button> */}
                                </h5>
                            </div>
                            <p className='button-optionstablebookingdesktop'>
                                <p className='button-optionstablebookingdesktop'>
                                    <Button className='button-all' onClick={() => setFilter('All')}          >          All</Button>
                                    <Button className='button-all' onClick={() => setFilter('Upcoming')}     > Upcoming</Button>
                                    <Button className='button-all' onClick={() => setFilter('Past')}         >Past Bookings</Button>
                                    <Button className='button-all' onClick={() => setFilter('Cancelled')}    >Cancelled</Button>
                                    <Button className='button-all' onClick={() => setFilter('Confirmation')} >Confirmation</Button>
                                </p>
                            </p>
                            <div className='row'>
                                {filteredBookings && Array.isArray(filteredBookings) && filteredBookings.length > 0 ? (
                                    filteredBookings.map(booking => (
                                        <div className='col-6'
                                            key={booking.id}>
                                            <CustomTableCard
                                                booking={booking}
                                            />
                                        </div>
                                    ))
                                ) : (
                                    <p>No bookings available</p>
                                )}
                            </div>
                        </Container>

                        <Modal show={show} onHide={handleClose}
                            id="addr-book-modalsetting"
                            className={theme === "dark" ? "dark-popup" : null}>
                            <Modal.Body >
                                {current === "Modalcontenttable" && <Modalcontenttable />}
                                {current === "Reviewmodal" && <Reviewmodal />}
                                {/* {current === "Deleteconform" && <DeleteConform />}  */}
                            </Modal.Body>
                        </Modal>
                    </div>
                </>
            ) : (
                <>
                    <div className='yourorder-fk'>
                        <Container>
                            <div>
                                <h5 className='tablebooking-arrow'>
                                    {/* <img src={ */}
                                    <MdOutlineArrowBackIos className='arrowtop-iconsrating' onClick={() => navigate('/myaccount')}/>
                                    {/* <span className='booking-btn'>Booking</span> */}
                                    <Button className="booktable-button" size='small'>
                                        <img src="https://fusionbucket.co.uk/My-account/vectorplus.png" alt="booking-image" /> Book Table
                                    </Button>
                                </h5>
                            </div>
                            <p className='button-optionstablebooking'>
                                <Button className='button-all' onClick={() => setFilter('All')}  >All</Button>
                                <Button className='button-all' onClick={() => setFilter('Upcoming')} >Up Coming</Button>
                                <Button className='button-all' onClick={() => setFilter('Past')}  >Past Bookings</Button>
                                <Button className='button-all' onClick={() => setFilter('Cancelled')}>Cancelled</Button>
                                <Button className='button-all' onClick={() => setFilter('Confirmation')}>Confirmation</Button>
                            </p>
                            {filteredBookings && Array.isArray(filteredBookings) && filteredBookings.length > 0 ? (
                                filteredBookings.map(booking => (
                                    <CustomTableCard key={booking.id} booking={booking} />)
                                )
                            ) : (
                                <p>No bookings available</p>
                            )}
                        </Container>

                        <Modal show={show} onHide={handleClose} dialogClassName="bottom-modaladdress"
                            className={theme === "dark" ? "dark-popup" : null}>
                            <Modal.Body >
                                {current === "Modalcontenttable" && <Modalcontenttable />}
                                {current === "Reviewmodal" && <Reviewmodal />}
                                {/* {current === "Deleteconform" && <DeleteConform />}  */}
                            </Modal.Body>
                        </Modal>
                    </div>
                </>
            )}
        </section>

    );
};

const mapStateToProps = (state) => ({
    tableBooking: state.tableBooking.tableBooking
});
const mapDispatchToProps = {
    getTableBooking
};
export default connect(mapStateToProps, mapDispatchToProps)(Tablebooking);


