import React, { useState, useEffect } from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import { Container } from 'react-bootstrap';
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';
import { FaHeart, FaClock, FaMapMarkerAlt, FaStar } from 'react-icons/fa';
import './favouriteorder.scss';
import { useContext } from "react";
import { connect } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import ThemeContext from "../../../../common/theme/components/contexts/themecontexts";
import {
    MdOutlineArrowBackIos,
    MdOutlineKeyboardArrowDown,
} from "react-icons/md";
import { getFavouriteList } from '../../../../actions/myaccount/favourite/favouriteAction';

// const restaurantData = [
//     {
//         name: 'Hotel Saravana Bavan',
//         description: 'Pure veg · South Indian',
//         image: 'https://fusionbucket.co.uk/My-account/restaurants.png',
//         time: '36 Mins ·',
//         distance: '1.5 KM',
//         rating: '4.0',
//         discount: '20% OFF Up To ₹50',
//     }
// ];



const RestaurantCard = ({ restaurant, getFavouriteList, favourite, key }) => {
    return (
        <Card className="restaurant-card" key={key}>
            <div className="image-container">
                <Card.Img variant="top" src={restaurant.bg_image} alt={restaurant.name} className="restaurant-image" />
                <FaHeart className="heart-icon" />
                {/* <div className="info-item">
                    <span>{restaurant?.time}</span>
                    <span>{restaurant?.distance}</span>
                </div> */}
            </div>
            <Card.Body className='card-bodyfavourite'>
                <h6 className='restaurant-namerating'> {restaurant.name} <span className='ratingstar'>{restaurant?.rating?.rate} <FaStar className='star-iconsrating' /></span></h6>
                <p className="text-res">{restaurant?.description}</p>
                <p variant="primary" className="discount-btn">
                    <img src="https://fusionbucket.co.uk/My-account/discountfavout.png" alt="discount-image" className='discount-image' />
                    {restaurant?.discount &&
                        typeof restaurant.discount === 'object' &&
                        Object.keys(restaurant.discount).length > 0 && (
                            <>
                                {restaurant.discount.discount} {restaurant.discount.discountType} off up to {restaurant.discount.minOrder}
                            </>
                        )
                    }

                </p>
            </Card.Body>
        </Card>
    );
};

const Favouriteorder = ({ getFavouriteList, favourite }) => {
    const [width, setWidth] = useState(window.innerWidth);
    const breakpoint = 767;
    const favouritelist = favourite?.message?.owner_name
    const { theme } = useContext(ThemeContext);
    const Imagetopdark = 'https://fusionbucket.co.uk/My-account/arrowwhitecolor.png';
    const Imagetop = 'https://fusionbucket.co.uk/Account-details /Arrow-final.png';
    const [active, setActive] = useState('Delivery');
    const [restaurantData, setRestaurantData] = useState(null);
    const handleClick = () => {
        setActive(prevState => (prevState === 'Delivery' ? 'Dining' : 'Delivery'));
    };
    const navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {
        const handleResizeWindow = () => setWidth(window.innerWidth);
        // subscribe to window resize event "onComponentDidMount"
        window.addEventListener("resize", handleResizeWindow);
        return () => {
            // unsubscribe "onComponentDestroy"
            window.removeEventListener("resize", handleResizeWindow);
        };
    }, []);

    // Api Call
    useEffect(() => {
        const data = {
            "customer_id": 30014,
            "orderTime": "2024-10-19 12:45"
        };
        getFavouriteList(data);
    }, [])
    // gwt Api Call
    useEffect(() => {
        setRestaurantData(favourite?.message);
    }, [favourite])
    if (location.pathname.includes("/profileV2") && width > breakpoint) {
        navigate("/myaccount");
    }
    return (
        <>
            {width > breakpoint ? (<div className='favourite-full'>

                <Container>
                    <h5 className='favourite-text'>
                        <img src={theme === "dark" ? Imagetopdark : Imagetop} className='arrow-iconfavourite' onClick={() => navigate('/myaccount')} />
                        Your Favourite Restaurants
                    </h5>
                    <div className="line-container">
                        <div className="line"></div>
                        <div className="text">ALL RESTAURANTS</div>
                        <div className="line"></div>
                    </div>

                </Container>
                <Container>
                    <div className="App">
                        <div className="card-container">
                            {restaurantData && restaurantData?.map((restaurant, index) => (
                                <RestaurantCard key={index} restaurant={restaurant} favourite={favourite} getFavouriteList={getFavouriteList} />
                            ))}
                        </div>
                    </div>
                </Container>
            </div>) : (
                <div className='favourite-full'>
                    <Container>
                        <h5 className='favourite-text'>
                            <MdOutlineArrowBackIos className='arrowtop-icon' onClick={() => navigate('/myaccount')} />
                            Your Favourite Restaurants
                        </h5>
                        <div className="line-container">
                            <div className="line"></div>
                            <div className="text">ALL RESTAURANTS</div>
                            <div className="line"></div>
                        </div>

                    </Container>
                    <Container>
                        <div className="App">
                            <div className="card-container">
                                {restaurantData && restaurantData?.map((restaurant, index) => (
                                    <RestaurantCard key={index} restaurant={restaurant} favourite={favourite} getFavouriteList={getFavouriteList} />
                                ))}
                            </div>
                        </div>
                    </Container>
                </div >
            )
            }

        </>
    );
};

const mapStateToProps = (state) => ({
    favourite: state.favourite.favourite
});
const mapDispatchToProps = {
    getFavouriteList
};
export default connect(mapStateToProps, mapDispatchToProps)(Favouriteorder);
