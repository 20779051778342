// src/actions/myaccount/orderstatus/orderTrackingActions.jsx
import { getTableBookingApi } from "../../../api/myaccount/tablebooking/tableBookingApi";
import {
  GET_TABLEBOOKING_SUCCESS,
  GET_TABLEBOOKING_FAILURE,
} from "../../types";

export const getTableBookingSuccess = (ordertracking) => ({
  type: GET_TABLEBOOKING_SUCCESS,
  payload: ordertracking,
});

export const getTableBookingFailure = (error) => ({
  type: GET_TABLEBOOKING_FAILURE,
  payload: error,
});

export const getTableBooking = (postData) => {
  return (dispatch) => {
    getTableBookingApi(postData)
      .then((response) => {
        dispatch(getTableBookingSuccess(response.data));
        return response.data;
      })
      .catch((error) => {
        dispatch(getTableBookingFailure(error.message));
        return error.message;
      });
  };
};
