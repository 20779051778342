// *******~ Import ~******** //
// React
import React, { useState, useContext, useEffect, useMemo } from "react";
// Assets
import { Container, Image } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { useNavigate, useLocation } from "react-router-dom";
// Components
import ThemeContext from "../../../../common/theme/components/contexts/themecontexts";
import { GoBack } from "../backbtn";
// CSS
import "./css/addcardV3.scss";
import "./css/addpopup.scss";
// Images
import CardImg from "./img/Credit_Card.svg";
import AddPopupImg from "./img/addpopup.svg";
// Icons
import { BsFillPlusCircleFill } from "react-icons/bs";
import { ImCancelCircle } from "react-icons/im";
// *******~ Import ~******** //

// Stripe Code for import
import { loadStripe } from "@stripe/stripe-js";
import {
  CardElement,
  Elements,
  useElements,
  useStripe,
  CardNumberElement,
  CardExpiryElement,
} from "@stripe/react-stripe-js";

// Stripe Code for import
import { connect } from "react-redux";
import { addSaveCard } from "../../../../actions/myaccount/wallet/addSaveCardActions";

const CreatePaymentMethod = ({ addnewcard }) => {
  const stripe = useStripe();
  const elements = useElements();
  const [errorMessage, setErrorMessage] = useState(null);

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!stripe || !elements) {
      return;
    }

    const { error, paymentMethod } = await stripe.createPaymentMethod({
      type: "card",
      card: elements.getElement(CardNumberElement),
    });

    if (error) {
      setErrorMessage(error.message);
      return;
    }

    addnewcard(paymentMethod.id);
  };

  return (
    <div className="form-addcard">
      <form onSubmit={handleSubmit}>
        <Form.Group className="mb-3" controlId="formCardNumber">
          <Form.Label>Card Number</Form.Label>
          <CardNumberElement className="InputElement" />
        </Form.Group>

        <Form.Group className="mb-3" controlId="formCardExpiry">
          <Form.Label>Expires on</Form.Label>
          <CardExpiryElement className="InputElement" />
        </Form.Group>

        <div className="submit-btn">
          <Button  type="submit" disabled={!stripe || !elements}>
            <BsFillPlusCircleFill /> Add Card
          </Button>
        </div>

        {errorMessage && <div>{errorMessage}</div>}
      </form>
    </div>
  );
};

const AddCardV3 = ({ error, userData, addSaveCard, adsavecard, paymentId }) => {
  const [width, setWidth] = useState(window.innerWidth);
  const { theme } = useContext(ThemeContext);
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [savecarderror, setSavecarderror] = useState(null);

  useEffect(() => {
    if (adsavecard && !error) {
      const SavecardStatus = adsavecard.status;
      const Savecardmessage = adsavecard.message;
      SavecardStatus
        ? setShow(SavecardStatus)
        : setSavecarderror(Savecardmessage);
    }
  }, [adsavecard, error]);

  const breakpoint = 767;

  useEffect(() => {
    const handleResizeWindow = () => setWidth(window.innerWidth);
    window.addEventListener("resize", handleResizeWindow);
    return () => {
      window.removeEventListener("resize", handleResizeWindow);
    };
  }, []);

  const navigateto = useNavigate();
  const location = useLocation();
  if (!location.pathname.includes("/myaccount") && width > breakpoint) {
    navigateto("/myaccount/addcardV3");
  }

  const addnewcard = useMemo(
    () => async (paymentId) => {
      await addSaveCard({
        customer_id: userData.customerId,
        payment_method_id: paymentId,
      });
    },
    [addSaveCard, userData]
  );

  const [stripe, setStripe] = useState(null);

  useEffect(() => {
    const loadStripeObject = async () => {
      const stripeObject = await loadStripe(
        "pk_live_DIodJvzwckwG0omwLcjh3E2k00A880PBDB"
      );
      setStripe(stripeObject);
    };

    loadStripeObject();
  }, []);

  if (error) {
    return <div>Error: {error}</div>;
  }

  if (!userData) {
    navigateto("/");
  }

  return (
    <>
      <section className="add-card">
        {width > breakpoint ? (
          !location.pathname.includes("/myaccount") ? (
            <GoBack name="Back to Save Card" />
          ) : null
        ) : (
          <GoBack name="Back to Save Card" />
        )}
        <Container>
          <h3 className="heading">Add Wallet Card</h3>
          {stripe && (
            <Elements stripe={stripe}>
              <CreatePaymentMethod addnewcard={addnewcard} />
            </Elements>
          )}
          <div>{savecarderror}</div>
        </Container>

        <Modal
          show={show}
          onHide={handleClose}
          backdrop="static"
          keyboard={false}
          centered
          id="add-card-popup"
          className={theme === "dark" ? "dark-popup" : null}
        >
          <Modal.Body>
            <Button className="cancel-btn" onClick={handleClose}>
              <ImCancelCircle />
            </Button>
            <Image src={AddPopupImg} fluid />
            <p>Your card details have been saved successfully!</p>
          </Modal.Body>
        </Modal>

        <Image className="card-image" src={CardImg} fluid />
      </section>
    </>
  );
};

const cardElementStyle = {
  base: {
    fontSize: "16px",
    fontFamily: "SegoeUI-SemiBold",
    color: "white",
    border: "1px solid #ddd",
  },
  invalid: {
    color: "#fa755a",
    iconColor: "#fa755a",
  },
};

const mapStateToProps = (state) => ({
  adsavecard: state.adsavecard.adsavecard,
  userData: state.userdata.userData,
});

const mapDispatchToProps = {
  addSaveCard,
};

export default connect(mapStateToProps, mapDispatchToProps)(AddCardV3);
